import { Component } from "react";
import { useLocation } from 'react-router-dom';

function $(x) {return document.getElementById(x);}

export default function Article(){
    const { search } = useLocation();
    const match = search.match(/type=(.*)/);
    const type = match?.[1];
    var article;

    async function getContent(){
        const id = type.split('/')[2];
        try{
            return await fetch('https://localhost:7089/api/articles/get-article/'+id, 
            {method: 'GET', mode: 'cors', 
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Accept': 'application/json'}})
            .then(response => response.json())
            .then(data => {
                // debug
                $('article-content').innerHTML = data.content;
            });
        }
        catch(e){
            console.error("Could not fetch article " + id + ": ", e);
        }
    }

    getContent();

    return (
        <div id="article-content">
            
        </div>
    );
}