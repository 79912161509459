export default class Article{
    /**
     * 
     * @param {number} id 
     * @param {string} category 
     * @param {string} title 
     * @param {string} author 
     * @param {string} content 
     */
    constructor(id, category, title, author, content){
        this.id = id;
        this.category = category;
        this.title = title;
        this.author = author;
        this.content = content;
        
    }
}