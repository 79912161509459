import { Component } from "react";
import './AskFeed.css';
import './Home.css';

class AskFeed extends Component{
    render(){
        return(
            <div className="feed-title">
                <span>People are asking...</span>
            </div>
        );
    }
}

export default AskFeed;