import { Component } from "react";
import AskFeed from "./AskFeed";
import './Home.css';
import NewArticleFeed from "./NewArticleFeed";
import ReplyFeed from "./ReplyFeed";

function $(x) {return document.getElementById(x);}

class Home extends Component{
    constructor(props){
        super(props);
        this.state = {loggedIn: this.props.loggedIn, currentUser: this.props.currentUser};
    }

    welcomeAnimation(){
        try{
            var containers = [$("replyfeed"), $("newarticlefeed")];
            containers.forEach(function(el){
                el.animate([
                    {height: "1vh"},
                    {},
                ],{
                    duration: 1000,
                    direction: 'normal',
                    iterations: 1,
                    easing: 'ease-in-out'
                });
            });
        }
        catch(e){
            console.error("error", e);
        }

    }

    componentDidMount(){
        this.welcomeAnimation();
    }

    render(){
        const Feeds = () => {
            if(this.state.loggedIn){
                return(
                    <>
                        <div className="replyfeed-container container" id="replyfeed">
                            <ReplyFeed/>
                        </div>
                        <div className="newarticlefeed-container container" id="newarticlefeed">
                            <NewArticleFeed/>
                        </div>
                    </>
                );
            }
            else{
                return(
                    <>
                        <div className="replyfeed-container container" id="replyfeed">
                            <AskFeed/>
                        </div>
                        <div className="newarticlefeed-container container" id="newarticlefeed">
                            <NewArticleFeed/>
                        </div>
                    </>
                );
            }
        }

        return(
            <div className="home" id="home">
                {Feeds()}
            </div>
        );
    }
}

export default Home;