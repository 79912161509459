import React, { Component } from "react";
import './NewArticleFeed.css';
import './Home.css';
import Article from "../Models/Article";

function $(x) {return document.getElementById(x);}

class FeedItem{
    /**
     * 
     * @param {Article} a 
     */
    constructor(a){
        this.article = '<a class="new-articles-feed-item" href="/articles?type=' + a.category + '/' + a.title + '/' + a.id + '">' + a.category + ' - ' + a.title + '</a>'
    }
}

class NewArticleFeed extends Component{
    constructor(props){
        super(props);
        this.state = {feedItems: []};
        
    }

    async mapArticlesToFeed(){
        if(this.state.feedItems !== null){
            try{
                var feedContainer = $('new-articles-feed');
                var feedHtml = ''; 
                this.state.feedItems.forEach(item => {
                    feedHtml += item.article
                });
                feedContainer.innerHTML = feedHtml;
            }
            catch(e){
                console.error('Unable to add articles to feed: ', e);
            }
        }
    }

    // fetch new articles from api/new-articles-feed
    async getNewArticles(){
        try{
            await fetch('https://localhost:7089/api/articles/new-articles', 
            {method: 'GET', mode: 'cors', 
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', 'Accept': 'application/json'}})
            .then(response => response.json())
            .then(data => {
                // debug
                console.log(data);
                let feedItems = [];
                data.forEach(el => {
                    let article = new Article(el.id, el.category, el.title, el.author, el.content);
                    let feedItem = new FeedItem(article);
                    feedItems.push(feedItem);
                });
                this.setState({feedItems: feedItems});
                // debug
                console.log(feedItems);
            });

            this.mapArticlesToFeed();
        }
        catch(e){
            console.error("Could not fetch new articles feed: ", e);
        }
    }

    componentDidMount(){
        // fill the the div with new articles links
        this.getNewArticles();
    }

    render(){
        return(
            <div className="feed-title">
                New Articles
                <div id="new-articles-feed">
                    
                </div>
            </div>
        );
    }
}

export default NewArticleFeed;