import { Component } from "react";
import './ReplyFeed.css';
import './Home.css';

class ReplyFeed extends Component{
    render(){
        return(
            <div className="feed-title">
                <span>Replies</span>
            </div>
        );
    }
}

export default ReplyFeed;