import { Component } from "react";
import './TopBar.css';

function $(x) {return document.getElementById(x);}

class TopBar extends Component{
    constructor(props){
        super(props);
        this.state = {loggedIn: this.props.loggedIn, currentUser: this.props.currentUser};
        this.welcomeAnimation = this.welcomeAnimation.bind(this);
    }

    welcomeAnimation(){
        try{
            var brandName = $('brand-name');
            var navBarItems = $('nav-bar').querySelectorAll('nav');
            var dur = 800;
            navBarItems.forEach(function(el){
                el.animate([
                    {transform: "translate(0vw, 20vmin) rotate(45deg)"},
                    {transform: "translate(0vw, 1vh)"}
                ],{
                    duration: dur,
                    direction: 'normal',
                    iterations: 1,
                    easing: 'ease-in-out'
                });
                dur += 150;
            });
        }
        catch(e){
            console.error(e);
        }


    }

    componentDidMount(){
        this.welcomeAnimation();
    }

    render(){
        const NavBarItems = () => {
            if(this.state.loggedIn){
                return (
                    <div className="nav-bar" id="nav-bar">
                        <nav className="nav-item tab">
                            <a>Search</a>
                        </nav>
                        <nav className="nav-item tab">
                            <a>Ask</a>
                        </nav>
                        <nav className="nav-item tab">
                            <a>Following</a>
                        </nav>
                        <nav className="nav-item tab">
                            <a>Profile</a>
                        </nav>
                    </div>
                );
            }
            else{
                return(
                    <div className="nav-bar" id="nav-bar">
                        <nav className="nav-item tab">
                            <a>Search</a>
                        </nav>
                        <nav className="nav-item tab">
                            <a>Ask</a>
                        </nav>
                        <nav className="nav-item tab">
                            <a>Login</a>
                        </nav>
                    </div>
                );
            }
        }
        return(
            <div className="topbar">
                <a className="brand-name" id="brand-name" href="/">Open Period</a>
                    {NavBarItems()}
                <div className="page"></div>
            </div>
        );
    }
}

export default TopBar;