import './App.css';
import { Component } from 'react';
import TopBar from './components/TopBar';
import Home from './components/Home/Home';
import { BrowserRouter as Router, Routes, Route, Link, Switch } from 'react-router-dom';
import Article from './components/Article/Article';

class App extends Component {
	constructor(props){
		super(props);
		this.state = {loggedIn: true, currentUser: "philip"};
	}
	render(){
		return(
			<Router>
				<div className='app'>
					<TopBar
						loggedIn = {this.state.loggedIn}
						currentUser = {this.state.currentUser}
					/>
					<Routes>
						<Route exact path='/' element={<Home
							loggedIn = {this.state.loggedIn}
							currentUser = {this.state.currentUser}
						/>}/>
						<Route path='/articles' element={<Article
						/>}/>
						<Route path='/articles/:type' element={<Article
						/>}/>
					</Routes>
					{/* BottomBar */}
				</div>
			</Router>
		);
	}
}

export default App;
